@import "../../styles/var";
.pagination {
  // padding-top: 20px ;
  ul {
    display: flex;
    list-style: none;
    width: 100%;
    justify-content: center;
    padding: 0;
    margin: 0;
    li {
      padding: 5px;
      a {
        color: inherit;
        padding: 10px;
        text-decoration: none;
        width: 30px;
        height: 30px;
        display: inline-flex;
        justify-content: center;
        align-items: center;
      }
    }
    :global {
      .selected {
        a {
            background-color: map-get($colors , "color-primary" );
            color: map-get($colors , "bg-primary-light" );
            border-radius: 4px;
        }
      }
      .disabled{
        opacity: 0.5;
      }
    }
  }
}
