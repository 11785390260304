@import "../../styles/var";
.input {
  display: block;
  label{
    color: map-get($colors , "color-primary" );
    font-weight: 500;
    margin-bottom: 12px;
    margin-top: 20px;
  }
  .input_wrapper {
    position: relative;
    input {
      min-height: 38px;
      border: 1px solid #d8d6de;
      border-radius: 4px;
      width: 100%;
      padding: 6px 30px 6px 14px;
      outline:none;
      &:active,&:focus{
      border:1px solid map-get($colors, "color-primary-light" );
      }
    }
    .show_password{
        position: absolute;
        right: 10px;
        top: 0;
        bottom: 0;
        margin: auto;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 20px;
    }
    &.error{
        input{
            border: 1px solid map-get($colors , "red" );
        }
    }
  }
  .error {
    display: block;
    color: map-get($colors , "red" );
  }
}
