@import "../../styles/var";

.select {
  select {
    width: 100%;
    border: 1px solid #d8d6de;
    padding: 6px 30px 6px 14px;
    border-radius: 4px;
    outline: none;
    &:active,
    &:focus {
      border: 1px solid map-get($colors, "color-primary-light");
    }
  }
  .error {
    color: map-get($colors, "red");
  }
}
