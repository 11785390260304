@import "../../styles/var";

.sub_questions {
  .error {
    color: red;
  }
  .title {
    padding: 20px 0;
    font-weight: 600;
    font-size: 18px;
    border-bottom: 1px solid #eeeeee;
    &.flex {
      display: flex;
      justify-content: space-between;
      align-items: center;
      button {
        font-size: 14px;
      }
    }
  }
  .container {
    margin-left: 20px;
    margin-right: 20px;
  }
  .questions {
    background-color: map-get($colors, "primary-light");
    height: 100%;
    .question {
      padding: 20px;
      transition: all ease 0.5s;
      &.active {
        background-color: map-get($colors, "bg-primary-light");
      }
    }
  }
  .add_sub_question {
    .input_wrapper{
      margin-bottom: 10px;
    }
  }
  .options_wrapper {
    .add_new_option {
      display: flex;
      justify-content: right;
      span {
        cursor: pointer;
        user-select: none;
        color: map-get($colors , "color-primary" );
      }
    }
    .close_icon {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 100%;
      cursor: pointer;
    }
    .option{
      margin-bottom: 10px;
    }
  }
}
