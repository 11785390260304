.status {
    display: inline-block;
    border-radius: 4px;
    padding: 2px 6px;
    font-size: 12px;
    font-weight: 500;
    &.active {
        background-color: rgba(40, 199, 111, 0.12);
        color: #28c76f;
    }
    &.inactive {
        background-color: rgba(234, 84, 85, 0.12);
        color: #ea5455;
    }
}
