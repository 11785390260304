.avatar_name{
    display: inline-block ;
    width: 30px ;
    height: 30px ;
    background-color: rgb(239, 174, 174) ;
    border-radius: 50% ;
    text-align: center ;
    line-height: 30px ;
    margin-right: 8px ;
    color: #fff ;
    user-select: none ;
    position: relative;
}