@import "../../styles/var";
.button {
    background-color: map-get($colors, "color-primary");
    color: #fff;
    min-width: 40px;
    height: 38px;
    line-height: 38px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 5px 8px;
    border: none;
    cursor: pointer;
    border-radius: 5.02px;
    transition: all ease 0.1s;
    &:hover,
    &:focus,
    &:active {
        background-color: map-get($colors, "color-primary-light");
    }
    &.full_width {
        width: 100%;
        border: none;
    }
    &.disabled{
        background-color: #888888;
    }
}
