@import "../../styles/var";
.action {
  position: relative;
  .action_btn {
    cursor: pointer;
  }
  .actions_wrapper {
    position: absolute;
    top: 110%;
    left: 10px;
    border: 1px solid map-get($colors, "color-border");
    padding: 0 5px;
    z-index: 1;
    background-color: #fff;
    font-size: 14px !important;
    .actions {
      cursor: pointer;
      padding: 5px 10px;
      user-select: none;
      &:not(:last-child) {
        border-bottom: 1px solid map-get($colors, "color-border");
      }
    }
  }
  &.left{
    .actions_wrapper{
      left: auto;
      right:10px;
    }
  }
}
