@import "../../styles/var";
.email_template {
    width: 100%;
    padding: 20px;
    box-shadow: 1px 2px 5px 3px rgb(238, 238, 238);
    .email_header {
        display: flex;
        justify-content: space-between;
        .user_data {
            display: flex;
            align-items: center;
            position: relative;
            .header_name {
                font-weight: 500;
                line-height: 17px;
            }
            .email_to {
                font-size: 13px;
                .text{
                    opacity: 0.7;
                }
                .icon_down {
                    cursor: pointer;
                    position: relative;
                    padding: 2px;
                    border-radius: 2px;
                    &:hover {
                        background-color: rgb(244, 244, 244);
                    }
                }
            }
            .details_popup{
                position: absolute;
                display: block;
                top: 20px;
                left: 100%;
                max-width: 400px;
                width: 400px;
                border: 1px solid rgb(163, 163, 163);
                padding: 10px;
                background-color: #fff;
                font-size: 14px;
                z-index: 1;
                opacity: 1 !important;
                line-height: 25px;
                .titles{
                    text-align: right;
                    opacity: 0.7;
                }
            }
        }
        .extra_data {
            display: flex;
            line-height: 13px;
            >div {
                margin-left: 10px;
            }
        }
    }
    .email_body{
        max-width: 600px;
        margin: auto;
        padding-left: 30px;
        .description{
            padding: 20px 0;
        }
        .bottom_image{
            border-top: 1px solid rgb(228, 228, 228);
            .attachment_image{
                width: 150px;
                padding: 20px 0;
            }
        }
    }
}
