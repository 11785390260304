.table_wrapper {
  margin: 10px 0;
}
.add_new {
  display: flex;
  justify-content: right;
  button {
    a {
      text-decoration: none;
      color: #fff;
    }
  }
}
