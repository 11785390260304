$colors: (
    "bg-primary": var(--bg-primary),
    "primary-light": var(--primary-light),
    "bg-primary-light": var(--bg-primary-light),
    "color-primary": var(--color-primary),
    "color-primary-light": var(--color-light),
    "red": #ea5455,
    "color-border": var(--color-border),
    "text": var(--text),
);
$vertival-sidebar-width: 260px;
