@import "../../styles/var";
.breadcumb {
    display: block;
    &_list {
        margin: 0;
        padding: 0;
        list-style: none;
        list-style-type: none;
        li {
            display: inline-block;
            margin-right: 8px;
            a{
                text-decoration: none;
                color:map-get($colors , "color-primary" ) ;
            }
            &:after{
                content: "" ;
                margin-left: 8px ;
                background-image: url("../../assets/right-arrow.svg");
                width: 10px;
                height: 10px;
                display:inline-block ;
            }
            &:last-child{
                &:after{
                    content: initial ;
                }
            }
        }
    }
}
