@import "../../styles/var";
.sidebar {
  width: $vertival-sidebar-width;
  position: fixed;
  left: 0;
  top: 0;
  bottom: 0;
  background-color: map-get($colors, "bg-primary-light");
  z-index: 1000;
  padding-right: 3px;
  transition: ease-out 0.5s;
  &.collapsed {
    width: 100px;
    transition: ease-in 0.5s;
  }
  .sidebar_header {
    height: 64px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 20px;
    .logo {
      max-width: 125px;
    }
    .sidebar_collapse_button {
      width: 18px;
      height: 18px;
      border-radius: 50%;
      border: 2px solid #636363;
      position: relative;
      display: inline-flex;
      justify-content: center;
      align-items: center;
      &.sidebar_collapsed::after {
        content: "";
        width: 6px;
        height: 6px;
        border: 2px solid #636363;
        border-radius: 50%;
        display: inline-block;
      }
    }
  }
  .sidebar_menus {
    overflow-y: auto;
    height: 100%;
    padding: 0 10px;
    padding-bottom: 60px;
    &::-webkit-scrollbar {
      width: 6px;
    }
    &::-webkit-scrollbar-thumb {
      background-color: rgb(176, 176, 176);
      border-radius: 3px;
    }
  }
  .menu_item_wrapper {
    list-style: none;
    padding-left: 0;
    margin-top: 10px;
  }
  .sub_item_wrapper {
    .sub_items {
      margin-left: 0;
      list-style: none;
      padding-left: 0;
      .menu_item {
        padding-left: 0;
        position: relative;
        display: flex;
        padding-left: 30px;
        margin: 5px 0;
        a{
          text-transform: capitalize;
        }
        &::before {
          content: "";
          position: absolute;
          left: 22px;
          top: 40%;
          bottom: 0;
          width: 10px;
          height: 10px;
          display: inline-flex;
          border: 1px solid;
          margin-right: 10px;
          border-radius: 50%;
        }
      }
      li {
        padding-left: 0;
      }
    }
  }
}
.menu_item {
  display: flex;
  justify-content: space-between;
  border-radius: 4px;
  margin: 5px 0;
  &.active {
    background-color: map-get($colors, "color-primary");
    color: #fff;
  }
  a,
  button {
    padding: 9px 5px 9px 21px !important;
    border-radius: 4px;
    text-transform: capitalize;
  }
  a {
    text-decoration: none;
    color: inherit;
    display: flex;
    width: 100%;
    align-items: center;
    &:hover {
      color: inherit;
    }
    svg {
      margin-right: 15px;
      font-size: 20px;
    }
  }
  .expand_btn {
    border: none;
    background-color: map-get($colors, "bg-primary-light");
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: 0;
    &.expanded {
      background-color: map-get($colors, "bg-primary");
    }
    .btn_title {
      svg {
        margin-right: 15px;
        font-size: 20px;
      }
    }
  }
}
