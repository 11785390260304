.quill {
  background-color: #fff;
  :global {
    .ql-toolbar.ql-snow {
      display: none;
    }
    .ql-toolbar.ql-snow + .ql-container.ql-snow {
      border-top: 1px solid #ccc !important;
    }
  }
  &.no_border {
    :global {
      .ql-container.ql-snow {
        border: none;
      }
      .ql-toolbar.ql-snow + .ql-container.ql-snow{
        border-top: 0 !important;
      }
      .ql-editor{
        padding:0 0 10px 0;
      }
    }
  }
}
