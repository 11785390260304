.listing_content {
  position: relative;
  .overlay {
    position: absolute;
    right: 0;
    left: 0;
    top: 0;
    bottom: 0;
    background-color: rgba(255, 255, 255, 0.5);
  }
  :global {
    .table-responsive {
      overflow-x: visible;
      background-color: red !important;
    }
  }
}
