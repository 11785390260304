@import "../../styles/var";

.add_quiz_wrapper {
  color: map-get($colors, "text");
  .error{
    color: red;
  }
  label {
    color: map-get($colors, "color-primary");
    font-weight: 500;
    margin-bottom: 12px;
    margin-top: 20px;
  }
  .section {
    background-color: map-get($colors, "bg-primary-light");
    border-radius: 4px;
    margin-top: 20px;
    overflow: hidden;
    .inner_title {
      font-size: 18px;
      font-weight: 600;
      padding-bottom: 12px;
      border-bottom: 1px solid #eeeeee;
      button {
        font-size: 14px;
      }
    }
  }
  .question_type_decide {
    padding: 24px;
    .question {
      font-weight: 500;
      margin-bottom: 17px;
    }
    .checkbox {
      user-select: none;
      label {
        margin: 0;
        margin-left: 10px;
      }
    }
  }
  // .description {
  //   border-radius: 4px;
  //   padding: 5px 10px;
  //   border: 1px solid map-get($colors, "color-border");
  // }
  .parent_question {
    padding: 32px;
    .parent_option{
      margin-top: 20px;
    }
    .template {
      margin-top: 10px;
      .email_title {
        margin-top: 20px;
        margin-bottom: 10px;
        font-size: 20px;
        font-weight: 600;
      }
    }
  }
  :global {
    .codex-editor__redactor {
      padding-bottom: 50px !important;
    }
  }
  .email_fields {
    margin-top: 35px;
  }
  .add_sub_questions {
    .btn_add_list {
      display: flex;
      justify-content: right;
    }
    .sub_questions {
      background-color: #f7f7f7;
      .question {
        padding: 10px 20px;
        cursor: pointer;
        transition: all ease 0.5s;
        &.active {
          background-color: #fff;
          transition: all ease 0.5s;
        }
        &:not(:last-child) {
          border-bottom: 1px solid map-get($colors, "color-border");
        }
        .title {
          font-size: 15px;
          font-weight: 600;
          padding-bottom: 5px;
        }
        .sub_question_description {
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
          overflow: hidden;
          color: #5e5873;
          font-size: 14px;
        }
      }
    }
    .add_sub_question {
      padding-right: 20px;
    }
  }
  .category_list {
    padding: 32px;
    select option:disabled:not([value='-']) {
      background-color: #2f4ea1;
      color: #fff;
    }
  }
  .btn_wrapper {
    margin-top: 20px;
    display: flex;
    justify-content: right;
  }
}
