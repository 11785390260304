.spinner_wrapper {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999;
  .spinner_inner_wrapper {
    .icon{
        display: flex;
        margin-bottom: 20px;
        img{
          width: 100px;
        }
    }
    .spinner {
      width: 50px;
      height: 50px;
      margin: auto;
      border-radius: 50%;
      border: 2px solid #fff;
      border-top: 2px solid #3498db;
      animation: spin 2s linear infinite;
      position: relative;
    //   .spinner_inner{
    //     position: absolute;
    //     top: 0;
    //     bottom: 0;
    //     left: 0;
    //     right: 0;
    //     width: 50px;
    //     height: 50px;
    //     border: 2px solid red;
    //     border-radius: 50%;
    //   }
    }
    @keyframes spin {
      0% {
        transform: rotate(0deg);
      }
      100% {
        transform: rotate(360deg);
      }
    }
  }
}
