@import "../../styles/var";
.layout {
  display: block;
  width: 100%;
  .content {
    display: block;
    padding: 15px;
    position: relative;
    min-height: 100vh;
    &.logged_in {
      margin-left: $vertival-sidebar-width;
    }
  }
  .has_menu {
  }
  .page {
    display: block;
    width: 100%;
  }

  // .panel_overlay {
  //   position: absolute;
  //   right: 0;
  //   left: 0;
  //   top: 0;
  //   bottom: 0;
  //   background-color: rgba(0, 0, 0, 0.2);
  //   opacity: 0;
  //   width: 0;
  //   height: 0;
  //   transition: width 0.3s 0.3s, height 0.3s 0.3s, opacity 0.3s;
  // }

  // :global(.panel-active) {
  //   .panel_overlay {
  //     opacity: 1;
  //     width: 100%;
  //     height: 100%;
  //     transition: width 0.3s, height 0.3s, opacity 0.3s 0.3s;
  //   }
  // }
}
