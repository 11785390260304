.parent_option{
    .option{
        &:not(:last-child){
            margin-bottom: 10px;
        }
    }
    .add_option{
        cursor: pointer;
    }
}
.close_icon{
    cursor: pointer;
}